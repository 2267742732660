import React, {Suspense} from 'react'
import {useResource} from '@lookout/suspense'
import DOMPurify from 'dompurify'
import {
  Modal,
  Panel,
  PanelHeader,
  PanelDescription,
  PanelFooter,
  Button,
  HyperText,
} from '@lookout/ui'
import {WarningButton} from '../micro/warning-button'
import NetworkErrorBoundary from '../../lib/network-error-boundary'
import {deleteApp} from './apps-service'
import {ResourceSuccess} from './apps-utils'

const DeleteAppModal = ({options, onClosed}) => {
  const [deleteApplication, isPending, startDeleteApp] = useResource(deleteApp)
  const {applicationGuid, appName} = options

  return (
    <Modal className="delete-app-modal" onClosed={onClosed}>
      {({closeModal}) => (
        <Panel
          onSubmit={() => startDeleteApp({applicationGuid})}
          onClose={() => closeModal()}
        >
          {({notifyError}) => (
            <>
              <PanelHeader>{I18n.t('delete_app.header')}</PanelHeader>
              <PanelDescription>
                <HyperText
                  content={I18n.t('delete_app.message', {
                    appName: '{appName}',
                  })}
                >
                  <HyperText
                    key="appName"
                    className="deleted-app-name"
                    css={{fontWeight: 'bolder'}}
                    content={DOMPurify.sanitize(appName)}
                  />
                </HyperText>
              </PanelDescription>
              {deleteApplication && (
                <NetworkErrorBoundary
                  resetKeys={[deleteApplication]}
                  onError={() => notifyError(I18n.t('error.service.apps'))}
                >
                  <Suspense>
                    <ResourceSuccess
                      resource={deleteApplication}
                      onSuccess={result => closeModal(result)}
                    />
                  </Suspense>
                </NetworkErrorBoundary>
              )}
              <PanelFooter>
                <Button type="reset" className="cancel-button" autoFocus link>
                  {I18n.t('buttons.cancel')}
                </Button>
                <WarningButton
                  className="delete-app-button"
                  type="submit"
                  disabled={isPending}
                >
                  {isPending
                    ? I18n.t('delete_app.deleting')
                    : I18n.t('delete_app.action_button')}
                </WarningButton>
              </PanelFooter>
            </>
          )}
        </Panel>
      )}
    </Modal>
  )
}

export default DeleteAppModal
