import {
  IconButton,
  Button,
  Table,
  OSIcon,
  tableStyles,
  typography,
  useLoading,
  TableActionCell,
} from '@lookout/ui'
import _ from 'lodash'
import {MiscDelete, MiscPencil} from '@lookout/dingbats'
import React, {useCallback, useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import {format} from '../../lib/utils/date-fns-localized'
import {tableLayoutStyles} from '../../lib/styles/layout-styles'
import classes from '../../lib/utils/classes'
import {DoubleHeader} from '../micro/double-header'
import Fallback from '../micro/fallback'

const sortApps = apps => _.orderBy(apps, ['createdTime'], ['desc'])

const filterApps = (apps, filter) =>
  _.filter(apps, app =>
    filter
      ? _.lowerCase(app?.appName)?.indexOf(_.lowerCase(filter)) !== -1
      : apps
  )

const AppsListRow = ({app, modals, setModal}) => {
  const navigate = useNavigate()
  const showGetKeyModal = useCallback(
    (event, {applicationGuid}) => {
      event.stopPropagation()
      setModal({
        type: modals.GET_KEY_MODAL,
        options: {applicationGuid},
      })
    },
    [modals.GET_KEY_MODAL, setModal]
  )
  const showEditAppModal = useCallback(
    (event, options) => {
      event.stopPropagation()
      setModal({
        type: modals.EDIT_APP_MODAL,
        options,
      })
    },
    [modals.EDIT_APP_MODAL, setModal]
  )
  const showDeleteAppModal = useCallback(
    (event, options) => {
      event.stopPropagation()
      setModal({
        type: modals.DELETE_APP_MODAL,
        options,
      })
    },
    [modals.DELETE_APP_MODAL, setModal]
  )
  return useMemo(
    () => (
      <tr onClick={() => navigate(`/apps/${app.applicationGuid}`)}>
        <td css={[typography.breakText, {minWidth: 200}]}>
          <DoubleHeader
            className="app-name"
            top={app.appName}
            bottom={app.packageName}
          />
        </td>
        <td css={{minWidth: 100}}>
          <OSIcon className={classes('app-os', app.os)} os={app.os} />
        </td>
        <td css={[typography.breakText, {minWidth: 200}]}>
          <DoubleHeader
            className="app-developer"
            top={app.userName}
            bottom={app.userEmail}
          />
        </td>
        <td>
          <DoubleHeader
            className="app-created-time"
            top={format(app.createdTime, 'M/d/yyyy')}
            bottom={format(app.createdTime, 'p')}
          />
        </td>
        <TableActionCell>
          <Button
            key="get-key"
            className="get-key-button"
            onClick={event => showGetKeyModal(event, app)}
            link
          >
            {I18n.t('apps_list.action_buttons.key')}
          </Button>
          <IconButton
            key="edit"
            Icon={MiscPencil}
            className="edit-app-button"
            onClick={event => showEditAppModal(event, app)}
          />
          <IconButton
            key="delete"
            className="delete-app-button"
            Icon={MiscDelete}
            onClick={event => showDeleteAppModal(event, app)}
          />
        </TableActionCell>
      </tr>
    ),
    [app, navigate, showGetKeyModal, showEditAppModal, showDeleteAppModal]
  )
}

const AppsList = ({filter, apps, modals, setModal, isPending}) => {
  const appsResult = apps.value()
  const {elementRef} = useLoading(isPending)

  const sortedApps = useMemo(() => sortApps(appsResult), [appsResult])

  const filteredApps = useMemo(
    () => filterApps(sortedApps, filter),
    [sortedApps, filter]
  )
  return filteredApps?.length ? (
    <div css={{overflow: 'auto'}}>
      <Table
        className="apps-list-table"
        css={[
          tableStyles.striped,
          tableStyles.hover,
          tableStyles.cursorPointer,
          tableStyles.loading,
          tableLayoutStyles.fullWidth,
          {
            'thead tr th': {whiteSpace: 'nowrap'},
          },
        ]}
      >
        <thead>
          <tr>
            <th>{I18n.t('apps_list.col.app_name')}</th>
            <th>{I18n.t('apps_list.col.os')}</th>
            <th>{I18n.t('app_details.developer')}</th>
            <th>{I18n.t('apps_list.col.created_on')}</th>
            <th />
          </tr>
        </thead>
        <tbody ref={elementRef}>
          {filteredApps &&
            filteredApps.map(app => (
              <AppsListRow
                app={app}
                key={app.applicationGuid}
                modals={modals}
                setModal={setModal}
              />
            ))}
        </tbody>
      </Table>
    </div>
  ) : (
    <Fallback
      className="apps-list-empty"
      message={
        _.isEmpty(filter)
          ? I18n.t('apps_page.no_apps_at_all')
          : I18n.t('apps_page.no_apps_filter')
      }
    />
  )
}

export default AppsList
