import React, {Suspense, useCallback, useMemo} from 'react'
import {
  Button,
  Modal,
  Panel,
  PanelHeader,
  PanelFooter,
  Layout,
  typography,
  FormikInput,
  FormikSelect,
} from '@lookout/ui'
import {Form, Formik, useField} from 'formik'
import _ from 'lodash'
import {useResource} from '@lookout/suspense'
import NetworkErrorBoundary from '../../lib/network-error-boundary'
import {createApp, updateApp} from './apps-service'
import {ResourceSuccess} from './apps-utils'

const PanelLayout = ({isCreatePending, isUpdatePending, applicationGuid}) => {
  const [{value}] = useField('os')

  const osSelectOptions = useMemo(
    () => [
      {label: I18n.t('register_app.platform.ios'), value: 'ios'},
      {label: I18n.t('register_app.platform.android'), value: 'android'},
    ],
    []
  )

  return (
    <>
      <PanelHeader>
        {applicationGuid
          ? I18n.t('register_app.dialog_header_edit')
          : I18n.t('register_app.dialog_header_add_new')}
      </PanelHeader>
      <Layout horizontal>
        <FormikSelect
          className="os-select"
          inputId="os"
          name="os"
          options={osSelectOptions}
          label={I18n.t('register_app.platform_section')}
          placeholder={I18n.t('register_app.select_placeholder_platform')}
          isDisabled={!!applicationGuid}
          menuPortalTarget={global.document.body}
        />

        <div css={{flexGrow: 1, marginLeft: 20}}>
          {value === 'android' && (
            <FormikInput
              className="android-app-id"
              name="packageName"
              label={I18n.t('register_app.platform_id_android')}
              placeholder={I18n.t('register_app.platform_id_android')}
              inputStyle={{width: '100%'}}
              disabled={!!applicationGuid}
            />
          )}
          {value === 'ios' && (
            <FormikInput
              className="ios-app-id"
              name="packageName"
              label={I18n.t('register_app.platform_id_ios')}
              placeholder={I18n.t('register_app.platform_id_ios')}
              inputStyle={{width: '100%'}}
              disabled={!!applicationGuid}
            />
          )}
        </div>
      </Layout>
      <FormikInput
        className="app-name-input"
        name="appName"
        placeholder={I18n.t('register_app.input_placeholder_app_name')}
        label={I18n.t('register_app.input_label_app_name')}
        labelStyle={{...typography.h2, display: 'inline-flex'}}
        inputStyle={{width: '100%'}}
        css={{marginTop: 15}}
      />
      <FormikInput
        className="app-comments"
        name="comment"
        placeholder={I18n.t('register_app.input_placeholder_comments')}
        label={I18n.t('register_app.input_label_comments')}
        labelStyle={{...typography.h2, display: 'inline-flex'}}
        inputStyle={{width: '100%'}}
        css={{marginTop: 15}}
      />
      <PanelFooter>
        <Button type="reset" className="cancel-button" autoFocus link>
          {I18n.t('buttons.cancel')}
        </Button>
        <Button
          className="save-button"
          disabled={isCreatePending || isUpdatePending}
          type="submit"
        >
          {isCreatePending || isUpdatePending
            ? I18n.t('buttons.saving')
            : I18n.t('buttons.save_changes')}
        </Button>
      </PanelFooter>
    </>
  )
}

const RegisterAppModal = ({options = {}, onClosed}) => {
  const {applicationGuid, appName, comment, os, packageName} = options
  const [create, isCreatePending, startCreateApp] = useResource(createApp)
  const [update, isUpdatePending, startUpdateApp] = useResource(updateApp)

  const validate = useCallback(
    ({os: platform, packageName: appPackageName, appName: name}) => {
      let errors = {}

      if (!platform) {
        errors = {...errors, os: I18n.t('error.form.required')}
      }

      if (_.size(appPackageName) <= 0) {
        errors = {...errors, packageName: I18n.t('error.form.required')}
      }

      if (_.size(appPackageName) > 110) {
        errors = {...errors, packageName: I18n.t('error.form.max_length')}
      }

      if (_.size(name) <= 0) {
        errors = {...errors, appName: I18n.t('error.form.required')}
      }

      if (_.size(name) > 30) {
        errors = {
          ...errors,
          appName: I18n.t('error.form.app_name', {count: 30}),
        }
      }

      return errors
    },
    []
  )

  return (
    <Formik
      initialValues={{
        os: os || 'android',
        packageName: packageName || '',
        appName: appName || '',
        comment: comment || '',
      }}
      onSubmit={data => {
        if (applicationGuid)
          startUpdateApp({
            applicationGuid,
            data: _.pick(data, ['appName', 'comment']),
          })
        else
          startCreateApp({
            data: {
              ..._.pick(data, ['os', 'packageName', 'appName', 'comment']),
              userEmail: global.cache.admin.email,
              userName: global.cache.admin.name,
            },
          })
      }}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Modal
        className={`${applicationGuid ? 'edit-app' : 'register-app'}-modal`}
        onClosed={onClosed}
      >
        {({closeModal}) => (
          <Panel
            className={applicationGuid ? 'edit-app' : 'register-app'}
            onClose={() => closeModal()}
            as={Form}
          >
            {({notifyError}) => (
              <>
                {(create || update) && (
                  <NetworkErrorBoundary
                    resetKeys={[create, update]}
                    onError={() => notifyError(I18n.t('error.service.apps'))}
                  >
                    <Suspense>
                      <ResourceSuccess
                        resource={create || update}
                        onSuccess={result => closeModal(result)}
                      />
                    </Suspense>
                  </NetworkErrorBoundary>
                )}
                <PanelLayout
                  {...{
                    isCreatePending,
                    isUpdatePending,
                    applicationGuid,
                  }}
                />
              </>
            )}
          </Panel>
        )}
      </Modal>
    </Formik>
  )
}

export default RegisterAppModal
